import { AgentCreationParams, AgentJob } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'

export type AgentCreationState = {
  [agentId: string]: AgentCreationParams
}

const initialState: AgentCreationState = {}
const agentCreationSlice = createSlice({
  name: 'agentCreation',
  initialState,
  reducers: {
    setAgentCreationParams(state, action) {
      const { agentId, agentCreationParams } = action.payload
      state[agentId] = {
        ...state[agentId],
        ...agentCreationParams,
        isDirty: true,
      }
    },
    deleteAgentCreationParams(state, action) {
      const { agentId } = action.payload
      delete state[agentId]
    },
  },
})

export const { setAgentCreationParams, deleteAgentCreationParams } =
  agentCreationSlice.actions

export default agentCreationSlice.reducer

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import agentCreationReducer from './features/agents/agentCreationSlice'
import agentJobsReducer from './features/agent-jobs/agentJobsSlice'
import datasetsReducer from './features/datasets/datasetsSlice'
import datasetFilesReducer from './features/datasets/datasetFilesSlice'
import workspaceStateReducer from './features/workspace/workspaceStateSlice'
import queryResultReducer from './features/workspace/queryResultSlice'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  agentCreation: agentCreationReducer,
  agentJobs: agentJobsReducer,
  datasets: datasetsReducer,
  datasetFiles: datasetFilesReducer,
  workspaceState: workspaceStateReducer,
  queryResult: queryResultReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
